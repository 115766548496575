.navigation {
  max-width:1400px;
  margin:auto;
  padding: 40px;
}

.navigation ul {
  margin:0;
  padding:0;
  display: inline-block;
  margin:auto;
  text-align: center;
}

.navigation ul li {
  list-style-type:none;
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  margin-right: 40px;
}

.navigation ul li a {
  font-size: 18px;
  text-decoration: none;
  color:#1F2D3D;
}

.navigation ul li a:hover {
  color:#592DEA;
}

.navigation img {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  margin-right:80px;
}

.navigation .button {
  float: right;
  background-color: #592DEA;
  color:#fff;
  padding:10px 14px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  text-decoration: none;
  vertical-align: middle;
  line-height: normal;
  margin-top: -5px;
}

.navigation .link{
  float: right;
  background-color: transparent;
  text-decoration: none;
  color:#1F2D3D;
  padding:10px 18px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  text-decoration: none;
  vertical-align: middle;
  line-height: normal;
  margin-top: -5px;
}

.navigation .link:hover {
  color:#592DEA;
}

.navigation .button:hover {
  background-color: #461ECB;
}


/* CORE */

.container {
  max-width: 1400px;
  margin:auto;
  padding:40px;
}