h1 {
    font-size: 40px;
    line-height: 60px;
    text-align: center;
    color: #1F2D3D;
    font-weight: 400;
    max-width: 725px;
    margin:auto;
    margin-top: 60px;
}

.text-transition {
    background-color: #1F2D3D;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    color: #fff;
    padding:4px 12px 8px 12px;
    display: inline-block !important;
    margin-left: 8px; 
}

.marquee-container {
    padding:20px 0 0 0;
}
.squircle-purple {
    height:256px;
    width:256px;
    background-image: url('./images/squircle-purple.svg');
    background-position: center center;
    background-repeat: no-repeat;
    margin:22px;
}
.squircle-blue {
    height:256px;
    width:256px;
    background-image: url('./images/squircle-blue.svg');
    background-position: center center;
    background-repeat: no-repeat;
    margin:22px;
}
.squircle-green {
    height:256px;
    width:256px;
    background-image: url('./images/squircle-green.svg');
    background-position: center center;
    background-repeat: no-repeat;
    margin:22px;
}
.squircle-red {
    height:256px;
    width:256px;
    background-image: url('./images/squircle-red.svg');
    background-position: center center;
    background-repeat: no-repeat;
    margin:22px;
}
.squircle-orange {
    height:256px;
    width:256px;
    background-image: url('./images/squircle-orange.svg');
    background-position: center center;
    background-repeat: no-repeat;
    margin:22px;
}
.squircle-pink {
    height:256px;
    width:256px;
    background-image: url('./images/squircle-pink.svg');
    background-position: center center;
    background-repeat: no-repeat;
    margin:22px;
}

.up-squircle {
    margin-top: -44px;
}

.squircle img {
    padding:40px 40px 0px 30px;
}
.squircle p {
    padding: 0 20px 0 30px;
    color:#fff;
    font-size: 24px;
}

.texture {
    background-image: url(./images/texture.png);
    background-position: center left;
    background-repeat: repeat-x;
}

.center-text {
    text-align: center;
}
.button-sign-up {
    display: inline;
    background-color: #592DEA;
    color:#fff;
    padding:14px 16px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    text-decoration: none;
    vertical-align: middle;
    line-height: normal;
    margin:auto;
    font-size: 20px;
    max-width: 210px;
    text-align: center;
  }
  
 .button-sign-up:hover {
    background-color: #461ECB;
  }

.why-outcome-section {
    padding-top:200px;
}
.label {
    font-size:18px;
    color:#592DEA;
    font-weight: 600;
  }

h2 {
    font-size: 36px;
    color: #1F2D3D;
    line-height: 56px;
    max-width: 600px;
    font-weight: 400;
    margin-top: 12px;
    margin-bottom: 12px;
}


.screenshot {
    display: block;
    width: 50%;
    float: right;
    background: #F7F5FE;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    padding:60px 0 0 60px;
    box-sizing: border-box;
    overflow-x: hidden;
}
.screenshot img {
    padding:0;
    margin:0;
    display: block;
    border-left:1px solid #CFDBEB;
    border-top:1px solid #CFDBEB;
}

.listWrapper {
    display: block;
    width: 50%;
    float: left;
    padding:0px 40px 40px 0;
    box-sizing: border-box;
}
@media (max-width: 890px) {
    .screenshot {
        width:100%;
        padding-right: 0;
    }
    .listWrapper {
        width: 100%;
    }
}

.listWrapper ul {
    margin:0;
    padding:0;
}

.listWrapper ul li {
    list-style-type:none;
    margin:0;
    padding:0;
    font-size: 24px;
    font-weight: 400;
    color: #1F2D3D;
    border-bottom: 1px solid #CFDBEB;
    padding:40px 0;
}

.listWrapper ul li span {
    margin-right: 18px;
}

.no-border {
    border-bottom:0px !important;
}

.solutions-section {
    margin-top: 120px !important;
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
  }


.solution-item {
    float: left;
    width:25%;
    padding:40px 40px 40px 0;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    box-sizing: border-box;
}

@media (max-width: 890px) {
    .solution-item {
        width:50%;
    }
}
@media (max-width: 690px) {
    .solution-item {
        width:100%;
        padding-right: 0px;
    }
}

.solution-item h3 {
    margin:8px 0;
    padding:0;
    font-size: 22px;
    color: #1F2D3D;
}

.solution-item p {
    color: #738093;
    font-size: 18px;
    line-height: 28px;
    margin:0;
}

.solutions-section h2 {
    margin-bottom: 2px;
}

.block-text-center {
    display: block;
    text-align: center;
    width:100% !important;
    max-width: 100% !important;
    font-size: 28px;
}

.divider {
    height:2px;
    border-bottom: 1px solid #CFDBEB;
    width: 100%;
    margin: 80px 0;
}
.divider-no-margin {
    height:0px;
    border-bottom: 1px solid #CFDBEB;
    width: 100%;
}

.cta-section {
    text-align: center;
    padding-bottom: 0px;
}

.cta-section h2 {
    max-width: 100%;
    font-size: 32px;
    margin-bottom: 40px;
}

.cta-section img {
    width: 100%;
    max-width: 900px;
    -webkit-border-top-left-radius: 5px;
    -webkit-border-top-right-radius: 5px;
    -moz-border-radius-topleft: 5px;
    -moz-border-radius-topright: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border:1px solid #CFDBEB;
    border-bottom: 0px;
    display: block;
    margin:auto;
    margin-top: 80px;
}

.footer-section {
    text-align: center;
}

.footer-section span {
    display: block;
    font-size: 12px;
    color:#738093;
    margin-top: 8px;
}

.form-container {
    background-color: #fff;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    border:1px solid #CFDBEB;
    padding:40px;
    max-width: 560px;
    margin:auto;
    margin-top: 20px;
    margin-bottom: 80px;
}

.form-section, .pricing-container {
    text-align: center;
}

.form-section h1, .pricing-container h1 {
    margin-bottom: 40px;
    margin-top: 8px;
}

input[type=email] {
    width: 100%;
    padding: 20px 20px;
    color:#1F2D3D;
    font-size: 22px;
    margin: 8px 0;
    box-sizing: border-box;
    outline:0;
    border:1px solid #CFDBEB;
  }
  input[type=email]:focus {
    border:1px solid #461ECB;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }


  .button-request {
    display: block;
    width: 100%;
    background-color: #592DEA;
    color:#fff;
    padding:14px 16px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    text-decoration: none;
    vertical-align: middle;
    line-height: normal;
    margin:auto;
    font-size: 20px;
    text-align: center;
    border:0;
    outline: 0;
    margin-top: 12px;
  }

  .react-tabs {
    text-align: center;
  }

  .react-tabs__tab-list {
    display: inline-block;
    text-align: center;
    margin:0;
    padding:8px 4px;
    background-color: #E2E6EB;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
    text-align: center;
    list-style-type: none;
    margin:auto;
  }

  .react-tabs__tab {
    display: inline;
    font-size: 16px;
    padding:4px 14px;
    cursor: pointer;
  }
  
  .react-tabs a.priceLink {
    color:#592DEA;
    text-decoration: none;
  }

  .react-tabs a.priceLink:hover {
    color:#592DEA;
    text-decoration: underline;
  }



  .react-tabs__tab--selected {
    background-color: #fff;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
    padding:4px 14px;
  }
 
  .react-tabs__tab--selected:focus {
    border:0;
    outline: 0;
  }

  .react-tabs__tab span {
    color: #461ECB;
    margin-left: 6px;
  }

  .no-padding-top {
     padding-top:0px !important;
  }

  .no-margin-bottom {
    margin-bottom: 0px !important;
  }

  .pricing-columns {
    display: flex;
    flex-direction: row;
    max-width: 960px;
    margin:auto;
    margin-top: 40px;
  }
  
  .pricing-column {
    text-align: left;
    padding:40px;
    flex: 1;
    margin: 0 10px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }

  @media (max-width: 790px) {
    .pricing-columns {
        display: block;
    }
    .enterprise-column {
        margin-top: 40px;
    }
}

  .pricing-column img {
    display: block;
    margin-bottom: 12px;
  }

  .enterprise-column {
    background-color: #F7F5FE;
  }

  .pricing-label {
    font-size: 24px;
    padding:20px 20px;
  }

  .enterprise-column .pricing-label {
    color:#fff;
  }
  .pricing-column .title {
    font-size: 20px;
    margin-top: 12px;
}
  .price {
    font-size: 50px;
    margin:12px 0;
    color: #1F2D3D;
  }
  .white-price {
    color:#fff;
  }
  .violet-border {
    border:1px solid #461ECB;
  }

  .pricing-column .title {
    margin:0;
  }
  .pricing-column p {
    margin:0;
    color:#738093;
  }

  .pricing-desc-dark {
    color: #1F2D3D !important;
    margin-top: 18px !important;
    line-height: 24px;
  }

  .pricing-button {
    display: block;
    background-color: #592DEA;
    color:#fff;
    padding:14px 14px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    font-size: 18px;
    text-align: center;
    border-radius: 5px;
    border:1px solid #592DEA;
    text-decoration: none;
    vertical-align: middle;
    line-height: normal;
    margin-top:28px !important;
  }
  .pricing-button-second {
    display: block;
    background-color: #fff;
    border:1px solid #592DEA;
    color:#592DEA;
    padding:14px 14px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    font-size: 18px;
    text-align: center;
    border-radius: 5px;
    text-decoration: none;
    vertical-align: middle;
    line-height: normal;
    margin-top:28px !important;
  }

.free-tier {
    margin:36px 0;
}

.accordion {
    max-width: 880px;
    margin:auto;
    padding:0 80px;
}

.accordion h2 {
    margin-top:120px;
}

.accordion__heading {
    font-size: 24px;
    color:#1F2D3D;
}

.accordion__item {
    border-bottom:1px solid #CFDBEB;
    padding:22px 0;
    cursor: pointer;
}

.accordion__item:hover {
    background-color: #F9FAFC;
}

.accordion__panel {
    color: #738093 !important;
    line-height: 24px;
}

.linkTo {
    color:#592DEA;
    text-decoration: none;
    padding:18px 0;
    margin-bottom: 22px;
  }

.linkTo:hover {
    color:#592DEA;
    text-decoration: underline;
  }

  .margin-top-20 {
    margin-top: 20px;
  }



.product-page-hero {
    display: flex;
    flex-direction: row;
}

.product-page-hero h1 {
    text-align: left;
    margin:0;
}
.product-page-hero .label {
    text-align: left;
    margin:0;
}

.padding-top-large {
    padding-top:40px;
}
.padding-top-xlarge {
    padding-top:80px;
}

.product-button {
    display:inline-block;
    background-color: #592DEA;
    color:#fff;
    padding:14px 14px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    font-size: 18px;
    text-align: center;
    border-radius: 5px;
    border:1px solid #592DEA;
    text-decoration: none;
    vertical-align: middle;
    line-height: normal;
    margin-top:28px !important;
  }

  .product-page-hero p {
    font-size: 20px;
    line-height: 31px;
    color:#738093;
  }


  .process {
    text-align: center;
    padding-top: 80px;
  }

  .process h2 {
    width: 100%;
    max-width: 100%;
    margin-bottom: 60px;
  }

  .process-img {
    width: 100%;
    max-width: 931px;
  }



.product-section {
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
    margin-bottom: 100px;
    box-sizing: border-box;
}

.color-bg {
    padding:40px;
    box-sizing: border-box;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    overflow-x: hidden;
}

.footer-button {
    display:inline-block;
    background-color: #592DEA;
    color:#fff;
    padding:14px 14px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    font-size: 18px;
    text-align: center;
    border-radius: 5px;
    border:1px solid #592DEA;
    text-decoration: none;
    vertical-align: middle;
    line-height: normal;
    margin-top:0px;
    margin-bottom: 100px;
  }

  .product-section h2 {
    padding-right: 100px;
  }

  .product-page-hero h1, .product-page-hero p {
    padding-right: 100px !important;
  }

  .product-page-hero-img {
    overflow-x: hidden;
    flex-basis: 50%;
    flex-grow: 0;
  }

  .col {
    flex-basis: 50%;
    flex-grow: 0;
  }

  @media (max-width: 890px) {
    .col {
      flex: 0 0 100%;
    }
    .product-section {
      display: flex;
      flex-direction: column;
      overflow-x: hidden;
      margin-bottom: 100px;
      box-sizing: border-box;
  }
  .product-section h2 {
    margin-bottom:40px;
  }
}